import React from 'react';
import Accordion from './Accordion'; // Importing from the same directory
import './Accordion.css'; // Import the CSS file

const FAQ = () => {
  return (
    <div className="faq-container" style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', color: 'black' }}>
      <h3 className="faq-header" style={{ color: 'white' }}>Frequently Asked Questions</h3>
      <Accordion title="Who is Bang Pateng?">
        <p>Bang Pateng has been a prominent figure in the crypto world since 2017. With extensive experience in blockchain technology, node operations, and validator management, he has established himself as a knowledgeable and reliable resource in the Web3 space.</p>
        <p>Since 2017, Bang Pateng has been deeply involved in the cryptocurrency ecosystem. His journey began with a fascination for blockchain technology and its potential to revolutionize financial systems. Over the years, he has gained hands-on experience with various cryptocurrencies and blockchain networks, contributing to the growth and stability of these platforms.</p>
      </Accordion>
      <Accordion title="Node Operations and Validator Expertise?">
        <p>Bang Pateng specializes in managing and operating blockchain nodes and validators. His expertise extends to:</p>
        <p>- Node Management: Ensuring the smooth operation of blockchain nodes, including setup, configuration, and maintenance.</p>
        <p>- Validator Services: Offering reliable and efficient validator services to secure and support blockchain networks.</p>
        <p>- Tutorials and Guides: Providing comprehensive tutorials and guides for individuals and organizations looking to set up and manage their own nodes and validators.</p>
      </Accordion>
      <Accordion title="Commitment to Education?">
        <p>Bang Pateng is passionate about educating others in the crypto space. He has created various tutorials and resources to help newcomers and experienced users alike understand the intricacies of blockchain technology and node management. His aim is to empower others with the knowledge and tools needed to succeed in the evolving world of Web3.</p>
      </Accordion>
    </div>
  );
};

export default FAQ;

